import { ODataMap, ODataObjectMap } from '../types';
import { generateGraphQlQuery, generateGraphQlSchema } from '../utils';

const WorkOrderMap = {
  documentType: {
    bcKey: 'Document_Type',
    type: 'string',
    required: true,
  },
  no: {
    bcKey: 'No',
    type: 'string',
    required: true,
  },
  requestedServiceDate: {
    bcKey: 'Requested_Service_Date',
    type: 'date',
    required: false,
  },
  standingOrder: {
    bcKey: 'Standing_Order',
    type: 'boolean',
    required: false,
  },
  postSalesShipment: {
    bcKey: 'Post_Sales_Shipment',
    type: 'boolean',
    required: false,
  },
  checkoutsExist: {
    bcKey: 'Checkouts_Exist',
    type: 'boolean',
    required: false,
  },
  pickListPrinted: {
    bcKey: 'Pick_List_Printed',
    type: 'number',
    required: false,
  },
  returnsPrinted: {
    bcKey: 'Returns_Printed',
    type: 'number',
    required: false,
  },
  itemPostedQuantity: {
    bcKey: 'Item_Posted_Quantity',
    type: 'number',
    required: false,
  },
  itemPendingQuantity: {
    bcKey: 'Item_Pending_Quantity',
    type: 'number',
    required: false,
  },
  consumablePostedQuantity: {
    bcKey: 'Consumable_Posted_Quantity',
    type: 'number',
    required: false,
  },
  consumablePendingQuantity: {
    bcKey: 'Consumable_Pending_Quantity',
    type: 'number',
    required: false,
  },
  toolPostedQuantity: {
    bcKey: 'Tool_Posted_Quantity',
    type: 'number',
    required: false,
  },
  toolPendingQuantity: {
    bcKey: 'Tool_Pending_Quantity',
    type: 'number',
    required: false,
  },
  ospPostedQuantity: {
    bcKey: 'OSP_Posted_Quantity',
    type: 'number',
    required: false,
  },
  ospPendingQuantity: {
    bcKey: 'OSP_Pending_Quantity',
    type: 'number',
    required: false,
  },
  otherPostedQuantity: {
    bcKey: 'Other_Posted_Quantity',
    type: 'number',
    required: false,
  },
  personnelPostedQuantity: {
    bcKey: 'Personnel_Posted_Quantity',
    type: 'number',
    required: false,
  },
  personnelPendingQuantity: {
    bcKey: 'Personnel_Pending_Quantity',
    type: 'number',
    required: false,
  },
  location: {
    bcKey: 'Location',
    type: 'string',
    required: false,
  },
  responsibilityCenter: {
    bcKey: 'Responsibility_Center',
    type: 'string',
    required: false,
  },
  locationFilter: {
    bcKey: 'Location_Filter',
    type: 'string',
    required: false,
  },
  description: {
    bcKey: 'Description',
    type: 'string',
    required: false,
  },
  expectedUsage: {
    bcKey: 'Expected_Usage',
    type: 'number',
    required: false,
  },
  actualUsage: {
    bcKey: 'Actual_Usage',
    type: 'number',
    required: false,
  },
  enterprise: {
    bcKey: 'Enterprise',
    type: 'string',
    required: false,
  },
  xCoordinates: {
    bcKey: 'X_Coordinates',
    type: 'string',
    required: false,
  },
  yCoordinates: {
    bcKey: 'Y_Coordinates',
    type: 'string',
    required: false,
  },
  requirementsPlanned: {
    bcKey: 'Requirements_Planned',
    type: 'number',
    required: false,
  },
  zCoordinates: {
    bcKey: 'Z_Coordinates',
    type: 'string',
    required: false,
  },
  region: {
    bcKey: 'Region',
    type: 'string',
    required: false,
  },
  facility: {
    bcKey: 'Facility',
    type: 'string',
    required: false,
  },
  area: {
    bcKey: 'Area',
    type: 'string',
    required: false,
  },
  line: {
    bcKey: 'Line',
    type: 'string',
    required: false,
  },
  equipmentId: {
    bcKey: 'Equipment_ID',
    type: 'string',
    required: false,
  },
  companyIdNo: {
    bcKey: 'Company_ID_No',
    type: 'string',
    required: false,
  },
  genBusPostingGroup: {
    bcKey: 'Gen_Bus_Posting_Group',
    type: 'string',
    required: false,
  },
  sortMaster: {
    bcKey: 'Sort_Master',
    type: 'string',
    required: false,
  },
  fixedRecurrence: {
    bcKey: 'Fixed_Recurrence',
    type: 'boolean',
    required: false,
  },
  sortSequence: {
    bcKey: 'Sort_Sequence',
    type: 'string',
    required: false,
  },
  elapsedDays: {
    bcKey: 'Elapsed_Days',
    type: 'number',
    required: false,
  },
  problemCode: {
    bcKey: 'Problem_Code',
    type: 'string',
    required: false,
  },
  workCode: {
    bcKey: 'Work_Code',
    type: 'string',
    required: false,
  },
  reasonCode: {
    bcKey: 'Reason_Code',
    type: 'string',
    required: false,
  },
  priority: {
    bcKey: 'Priority',
    type: 'string',
    required: false,
  },
  status: {
    bcKey: 'Status',
    type: 'string',
    required: false,
  },
  orderType: {
    bcKey: 'Order_Type',
    type: 'string',
    required: false,
  },
  yourReference: {
    bcKey: 'Your_Reference',
    type: 'string',
    required: false,
  },
  maintType: {
    bcKey: 'Maint_Type',
    type: 'string',
    required: false,
  },
  documentTypeFilter: {
    bcKey: 'Document_Type_Filter',
    type: 'string',
    required: false,
  },
  supervisorCode: {
    bcKey: 'Supervisor_Code',
    type: 'string',
    required: false,
  },
  personnelGroup: {
    bcKey: 'Personnel_Group',
    type: 'string',
    required: false,
  },
  billable: {
    bcKey: 'Billable',
    type: 'boolean',
    required: false,
  },
  technicianCode: {
    bcKey: 'Technician_Code',
    type: 'string',
    required: false,
  },
  ospNo: {
    bcKey: 'OSP_No',
    type: 'string',
    required: false,
  },
  startingDatetime: {
    bcKey: 'Starting_Datetime',
    type: 'date',
    required: false,
  },
  expirationDate: {
    bcKey: 'Expiration_Date',
    type: 'date',
    required: false,
  },
  feedbackExist: {
    bcKey: 'Feedback_Exist',
    type: 'boolean',
    required: false,
  },
  commentCount: {
    bcKey: 'Comment_Count',
    type: 'number',
    required: false,
  },
  feedbackCount: {
    bcKey: 'Feedback_Count',
    type: 'number',
    required: false,
  },
  equipmentGroup: {
    bcKey: 'Equipment_Group',
    type: 'string',
    required: false,
  },
  equipmentSubgroup: {
    bcKey: 'Equipment_Subgroup',
    type: 'string',
    required: false,
  },
  hasPendingLines: {
    bcKey: 'Has_Pending_Lines',
    type: 'boolean',
    required: false,
  },
  dueByDate: {
    bcKey: 'Due_By_Date',
    type: 'date',
    required: false,
  },
  dateFilter: {
    bcKey: 'Date_Filter',
    type: 'string',
    required: false,
  },
  capitalExpenditure: {
    bcKey: 'Capital_Expenditure',
    type: 'boolean',
    required: false,
  },
  sameEquipmentLines: {
    bcKey: 'Same_Equipment_Lines',
    type: 'number',
    required: false,
  },
  shortcutDimension1Code: {
    bcKey: 'Shortcut_Dimension_1_Code',
    type: 'string',
    required: false,
  },
  shortcutDimension2Code: {
    bcKey: 'Shortcut_Dimension_2_Code',
    type: 'string',
    required: false,
  },
  jobNo: {
    bcKey: 'Job_No',
    type: 'string',
    required: false,
  },
  managedByTagProject: {
    bcKey: 'Managed_by_TAG_Project',
    type: 'boolean',
    required: false,
  },
  policyType: {
    bcKey: 'Policy_Type',
    type: 'string',
    required: false,
  },
  policyNo: {
    bcKey: 'Policy_No',
    type: 'string',
    required: false,
  },
  personnelCostStandard: {
    bcKey: 'Personnel_Cost_Standard',
    type: 'number',
    required: false,
  },
  orderSource: {
    bcKey: 'Order_Source',
    type: 'string',
    required: false,
  },
  documentTrackingNo: {
    bcKey: 'Document_Tracking_No',
    type: 'string',
    required: false,
  },
  inventoryEntryNo: {
    bcKey: 'Inventory_Entry_No',
    type: 'number',
    required: false,
  },
  inventoryTrigger: {
    bcKey: 'Inventory_Trigger',
    type: 'string',
    required: false,
  },
  languageCode: {
    bcKey: 'Language_Code',
    type: 'string',
    required: false,
  },
  orderClass: {
    bcKey: 'Order_Class',
    type: 'string',
    required: false,
  },
  requester: {
    bcKey: 'Requester',
    type: 'string',
    required: false,
  },
  newRecord: {
    bcKey: 'New_Record',
    type: 'boolean',
    required: false,
  },
  dmpNextServiceDate: {
    bcKey: 'DMP_Next_Service_Date',
    type: 'string',
    required: false,
  },
  dimensionSetId: {
    bcKey: 'Dimension_Set_ID',
    type: 'number',
    required: false,
  },
  createdByName: {
    bcKey: 'Created_By_Name',
    type: 'string',
    required: false,
  },
  levelParentDescription: {
    bcKey: 'Level_Parent_Description',
    type: 'string',
    required: false,
  },
  hierarchyParentDescription: {
    bcKey: 'Hierarchy_Parent_Description',
    type: 'string',
    required: false,
  },
  enterpriseDescription: {
    bcKey: 'Enterprise_Description',
    type: 'string',
    required: false,
  },
  facilityDescription: {
    bcKey: 'Facility_Description',
    type: 'string',
    required: false,
  },
  areaDescription: {
    bcKey: 'Area_Description',
    type: 'string',
    required: false,
  },
  regionDescription: {
    bcKey: 'Region_Description',
    type: 'string',
    required: false,
  },
  technicianName: {
    bcKey: 'Technician_Name',
    type: 'string',
    required: false,
  },
  toolCostPending: {
    bcKey: 'Tool_Cost_Pending',
    type: 'number',
    required: false,
  },
  toolCostPosted: {
    bcKey: 'Tool_Cost_Posted',
    type: 'number',
    required: false,
  },
  itemCostPending: {
    bcKey: 'Item_Cost_Pending',
    type: 'number',
    required: false,
  },
  itemCostPosted: {
    bcKey: 'Item_Cost_Posted',
    type: 'number',
    required: false,
  },
  ospCostPending: {
    bcKey: 'OSP_Cost_Pending',
    type: 'number',
    required: false,
  },
  ospCostPosted: {
    bcKey: 'OSP_Cost_Posted',
    type: 'number',
    required: false,
  },
  otherCostPending: {
    bcKey: 'Other_Cost_Pending',
    type: 'number',
    required: false,
  },
  otherCostPosted: {
    bcKey: 'Other_Cost_Posted',
    type: 'number',
    required: false,
  },
  jobTaskNo: {
    bcKey: 'Job_Task_No',
    type: 'string',
    required: false,
  },
  jobLineType: {
    bcKey: 'Job_Line_Type',
    type: 'string',
    required: false,
  },
  oldDocumentTrackingNo: {
    bcKey: 'Old_Document_Tracking_No',
    type: 'string',
    required: false,
  },
  webServiceUser: {
    bcKey: 'Web_Service_User',
    type: 'string',
    required: false,
  },
  equipSerialNo: {
    bcKey: 'Equip_Serial_No',
    type: 'string',
    required: false,
  },
  assetIdentifier: {
    bcKey: 'Asset_Identifier',
    type: 'string',
    required: false,
  },
  postingErrorText: {
    bcKey: 'Posting_Error_Text',
    type: 'string',
    required: false,
  },
  equipMain: {
    bcKey: 'Equip_Main',
    type: 'string',
    required: false,
  },
  equipPrimary: {
    bcKey: 'Equip_Primary',
    type: 'string',
    required: false,
  },
  equipSecondary: {
    bcKey: 'Equip_Secondary',
    type: 'string',
    required: false,
  },
  contractType: {
    bcKey: 'Contract_Type',
    type: 'string',
    required: false,
  },
  contractNo: {
    bcKey: 'Contract_No',
    type: 'string',
    required: false,
  },
  externalDocumentNo: {
    bcKey: 'External_Document_No',
    type: 'string',
    required: false,
  },
  personnelPriceEstimated: {
    bcKey: 'Personnel_Price_Estimated',
    type: 'number',
    required: false,
  },
  personnelPriceActual: {
    bcKey: 'Personnel_Price_Actual',
    type: 'number',
    required: false,
  },
  toolPriceEstimated: {
    bcKey: 'Tool_Price_Estimated',
    type: 'number',
    required: false,
  },
  toolPriceActual: {
    bcKey: 'Tool_Price_Actual',
    type: 'number',
    required: false,
  },
  itemPriceEstimated: {
    bcKey: 'Item_Price_Estimated',
    type: 'number',
    required: false,
  },
  itemPriceActual: {
    bcKey: 'Item_Price_Actual',
    type: 'number',
    required: false,
  },
  ospPriceEstimated: {
    bcKey: 'OSP_Price_Estimated',
    type: 'number',
    required: false,
  },
  ospPriceActual: {
    bcKey: 'OSP_Price_Actual',
    type: 'number',
    required: false,
  },
  otherPriceEstimated: {
    bcKey: 'Other_Price_Estimated',
    type: 'number',
    required: false,
  },
  otherPriceActual: {
    bcKey: 'Other_Price_Actual',
    type: 'number',
    required: false,
  },
  toolPricePending: {
    bcKey: 'Tool_Price_Pending',
    type: 'number',
    required: false,
  },
  toolPricePosted: {
    bcKey: 'Tool_Price_Posted',
    type: 'number',
    required: false,
  },
  itemPricePending: {
    bcKey: 'Item_Price_Pending',
    type: 'number',
    required: false,
  },
  itemPricePosted: {
    bcKey: 'Item_Price_Posted',
    type: 'number',
    required: false,
  },
  ospPricePending: {
    bcKey: 'OSP_Price_Pending',
    type: 'number',
    required: false,
  },
  ospPricePosted: {
    bcKey: 'OSP_Price_Posted',
    type: 'number',
    required: false,
  },
  otherPricePending: {
    bcKey: 'Other_Price_Pending',
    type: 'number',
    required: false,
  },
  otherPricePosted: {
    bcKey: 'Other_Price_Posted',
    type: 'number',
    required: false,
  },
  lockUnitPrice: {
    bcKey: 'Lock_Unit_Price',
    type: 'boolean',
    required: false,
  },
  serviceMeterStartDate: {
    bcKey: 'Service_Meter_Start_Date',
    type: 'string',
    required: false,
  },
  priorityRank: {
    bcKey: 'Priority_Rank',
    type: 'string',
    required: false,
  },
  woUnionNo: {
    bcKey: 'WO_Union_No',
    type: 'string',
    required: false,
  },
  unionNo: {
    bcKey: 'Union_No',
    type: 'string',
    required: false,
  },
  company: {
    bcKey: 'Company',
    type: 'string',
    required: false,
  },
  temperature: {
    bcKey: 'Temperature',
    type: 'number',
    required: false,
  },
  temperatureMeasure: {
    bcKey: 'Temperature_Measure',
    type: 'string',
    required: false,
  },
  weatherConditions: {
    bcKey: 'Weather_Conditions',
    type: 'string',
    required: false,
  },
  testResultsRequired: {
    bcKey: 'Test_Results_Required',
    type: 'number',
    required: false,
  },
  testResultsCompleted: {
    bcKey: 'Test_Results_Completed',
    type: 'number',
    required: false,
  },
  meterInputControl: {
    bcKey: 'Meter_Input_Control',
    type: 'boolean',
    required: false,
  },
  nextMeterService: {
    bcKey: 'Next_Meter_Service',
    type: 'number',
    required: false,
  },
  noPrinted: {
    bcKey: 'No_Printed',
    type: 'number',
    required: false,
  },
  orderDate: {
    bcKey: 'Order_Date',
    type: 'date',
    required: false,
  },
  postingDate: {
    bcKey: 'Posting_Date',
    type: 'string',
    required: false,
  },
  postingDescription: {
    bcKey: 'Posting_Description',
    type: 'string',
    required: false,
  },
  dueDate: {
    bcKey: 'Due_Date',
    type: 'string',
    required: false,
  },
  documentDate: {
    bcKey: 'Document_Date',
    type: 'string',
    required: false,
  },
  workOrderNo: {
    bcKey: 'Work_Order_No',
    type: 'string',
    required: false,
  },
  consumptionWo: {
    bcKey: 'Consumption_WO',
    type: 'string',
    required: false,
  },
  endingDatetime: {
    bcKey: 'Ending_Datetime',
    type: 'date',
    required: false,
  },
  standardTime: {
    bcKey: 'Standard_Time',
    type: 'number',
    required: false,
  },
  estimatedTime: {
    bcKey: 'Estimated_Time',
    type: 'number',
    required: false,
  },
  unfinishedLinesExist: {
    bcKey: 'Unfinished_Lines_Exist',
    type: 'boolean',
    required: false,
  },
  finishedLinesExist: {
    bcKey: 'Finished_Lines_Exist',
    type: 'boolean',
    required: false,
  },
  priorMeterReading: {
    bcKey: 'Prior_Meter_Reading',
    type: 'number',
    required: false,
  },
  actualTime: {
    bcKey: 'Actual_Time',
    type: 'number',
    required: false,
  },
  templateNo: {
    bcKey: 'Template_No',
    type: 'string',
    required: false,
  },
  totalStepTime: {
    bcKey: 'Total_Step_Time',
    type: 'number',
    required: false,
  },
  plannedOrders: {
    bcKey: 'Planned_Orders',
    type: 'number',
    required: false,
  },
  releasedOrders: {
    bcKey: 'Released_Orders',
    type: 'number',
    required: false,
  },
  personnelCostEstimated: {
    bcKey: 'Personnel_Cost_Estimated',
    type: 'number',
    required: false,
  },
  personnelCostActual: {
    bcKey: 'Personnel_Cost_Actual',
    type: 'number',
    required: false,
  },
  toolCostEstimated: {
    bcKey: 'Tool_Cost_Estimated',
    type: 'number',
    required: false,
  },
  toolCostActual: {
    bcKey: 'Tool_Cost_Actual',
    type: 'number',
    required: false,
  },
  itemCostEstimated: {
    bcKey: 'Item_Cost_Estimated',
    type: 'number',
    required: false,
  },
  itemCostActual: {
    bcKey: 'Item_Cost_Actual',
    type: 'number',
    required: false,
  },
  ospCostEstimated: {
    bcKey: 'OSP_Cost_Estimated',
    type: 'number',
    required: false,
  },
  ospCostActual: {
    bcKey: 'OSP_Cost_Actual',
    type: 'number',
    required: false,
  },
  otherCostEstimated: {
    bcKey: 'Other_Cost_Estimated',
    type: 'number',
    required: false,
  },
  otherCostActual: {
    bcKey: 'Other_Cost_Actual',
    type: 'number',
    required: false,
  },
  name: {
    bcKey: 'Name',
    type: 'string',
    required: false,
  },
  name2: {
    bcKey: 'Name_2',
    type: 'string',
    required: false,
  },
  address: {
    bcKey: 'Address',
    type: 'string',
    required: false,
  },
  address2: {
    bcKey: 'Address_2',
    type: 'string',
    required: false,
  },
  city: {
    bcKey: 'City',
    type: 'string',
    required: false,
  },
  county: {
    bcKey: 'County',
    type: 'string',
    required: false,
  },
  equipmentDescription: {
    bcKey: 'Equipment_Description',
    type: 'string',
    required: false,
  },
  countryCode: {
    bcKey: 'Country_Code',
    type: 'string',
    required: false,
  },
  correction: {
    bcKey: 'Correction',
    type: 'boolean',
    required: false,
  },
  postCode: {
    bcKey: 'Post_Code',
    type: 'string',
    required: false,
  },
  takeOos: {
    bcKey: 'Take_OOS',
    type: 'boolean',
    required: false,
  },
  equipTakenOos: {
    bcKey: 'Equip_Taken_OOS',
    type: 'boolean',
    required: false,
  },
  oosDays: {
    bcKey: 'OOS_Days',
    type: 'number',
    required: false,
  },
  oosHours: {
    bcKey: 'OOS_Hours',
    type: 'number',
    required: false,
  },
  oosMinutes: {
    bcKey: 'OOS_Minutes',
    type: 'number',
    required: false,
  },
  useStepTime: {
    bcKey: 'Use_Step_Time',
    type: 'boolean',
    required: false,
  },
  stepDays: {
    bcKey: 'Step_Days',
    type: 'number',
    required: false,
  },
  stepHours: {
    bcKey: 'Step_Hours',
    type: 'number',
    required: false,
  },
  stepMinutes: {
    bcKey: 'Step_Minutes',
    type: 'number',
    required: false,
  },
  contact: {
    bcKey: 'Contact',
    type: 'string',
    required: false,
  },
  orderSourceType: {
    bcKey: 'Order_Source_Type',
    type: 'string',
    required: false,
  },
  orderSourceNo: {
    bcKey: 'Order_Source_No',
    type: 'string',
    required: false,
  },
  createdBy: {
    bcKey: 'Created_By',
    type: 'string',
    required: false,
  },
  creationDate: {
    bcKey: 'Creation_Date',
    type: 'date',
    required: false,
  },
  lastModifiedBy: {
    bcKey: 'Last_Modified_By',
    type: 'string',
    required: false,
  },
  lastModified: {
    bcKey: 'Last_Modified',
    type: 'date',
    required: false,
  },
  closedBy: {
    bcKey: 'Closed_By',
    type: 'string',
    required: false,
  },
  closedDate: {
    bcKey: 'Closed_Date',
    type: 'string',
    required: false,
  },
  onHold: {
    bcKey: 'On_Hold',
    type: 'string',
    required: false,
  },
  vendorInvoice: {
    bcKey: 'Vendor_Invoice',
    type: 'string',
    required: false,
  },
  customerInvoice: {
    bcKey: 'Customer_Invoice',
    type: 'string',
    required: false,
  },
  overrideActualTime: {
    bcKey: 'Override_Actual_Time',
    type: 'number',
    required: false,
  },
  costOverride: {
    bcKey: 'Cost_Override',
    type: 'number',
    required: false,
  },
  readingChange: {
    bcKey: 'Reading_Change',
    type: 'number',
    required: false,
  },
  serviceMeter: {
    bcKey: 'Service_Meter',
    type: 'number',
    required: false,
  },
  serviceMeterDate: {
    bcKey: 'Service_Meter_Date',
    type: 'string',
    required: false,
  },
  resultsAccepted: {
    bcKey: 'Results_Accepted',
    type: 'boolean',
    required: false,
  },
  requestNo: {
    bcKey: 'Request_No',
    type: 'string',
    required: false,
  },
  pendingApprovalOrders: {
    bcKey: 'Pending_Approval_Orders',
    type: 'number',
    required: false,
  },
  finishedOrders: {
    bcKey: 'Finished_Orders',
    type: 'number',
    required: false,
  },
  releasedConsumptionOrders: {
    bcKey: 'Released_Consumption_Orders',
    type: 'number',
    required: false,
  },
  noSeries: {
    bcKey: 'No_Series',
    type: 'string',
    required: false,
  },
  requirementsReleased: {
    bcKey: 'Requirements_Released',
    type: 'number',
    required: false,
  },
  qualifications: {
    bcKey: 'Qualifications',
    type: 'number',
    required: false,
  },
  attachments: {
    bcKey: 'Attachments',
    type: 'number',
    required: false,
  },
  comment: {
    bcKey: 'Comment',
    type: 'boolean',
    required: false,
  },
  testResults: {
    bcKey: 'Test_Results',
    type: 'number',
    required: false,
  },
  meterHistory: {
    bcKey: 'Meter_History',
    type: 'number',
    required: false,
  },
  currentMeter: {
    bcKey: 'Current_Meter',
    type: 'number',
    required: false,
  },
  postedUsage: {
    bcKey: 'Posted_Usage',
    type: 'number',
    required: false,
  },
  parentNo: {
    bcKey: 'Parent_No',
    type: 'string',
    required: false,
  },
  level: {
    bcKey: 'Level',
    type: 'number',
    required: false,
  },
  splitCategory: {
    bcKey: 'Split_Category',
    type: 'string',
    required: false,
  },
  failureCode: {
    bcKey: 'Failure_Code',
    type: 'string',
    required: false,
  },
  consumptions: {
    bcKey: 'Consumptions',
    type: 'number',
    required: false,
  },
  allAttachments: {
    bcKey: 'All_Attachments',
    type: 'number',
    required: false,
  },
  totalBillingPrice1: {
    bcKey: 'Total_Billing_Price_1',
    type: 'number',
    required: false,
  },
  totalBillingPrice2: {
    bcKey: 'Total_Billing_Price_2',
    type: 'number',
    required: false,
  },
  totalBillingPrice: {
    bcKey: 'Total_Billing_Price',
    type: 'number',
    required: false,
  },
  postedTimeQty: {
    bcKey: 'Posted_Time_Qty',
    type: 'number',
    required: false,
  },
  recordedTimeQty: {
    bcKey: 'Recorded_Time_Qty',
    type: 'number',
    required: false,
  },
  personnelCostPending: {
    bcKey: 'Personnel_Cost_Pending',
    type: 'number',
    required: false,
  },
  personnelCostPosted: {
    bcKey: 'Personnel_Cost_Posted',
    type: 'number',
    required: false,
  },
  personnelPricePending: {
    bcKey: 'Personnel_Price_Pending',
    type: 'number',
    required: false,
  },
  personnelPricePosted: {
    bcKey: 'Personnel_Price_Posted',
    type: 'number',
    required: false,
  },
  packageCostEstimated: {
    bcKey: 'Package_Cost_Estimated',
    type: 'number',
    required: false,
  },
  packageCostActual: {
    bcKey: 'Package_Cost_Actual',
    type: 'number',
    required: false,
  },
  packageCostPending: {
    bcKey: 'Package_Cost_Pending',
    type: 'number',
    required: false,
  },
  packageCostPosted: {
    bcKey: 'Package_Cost_Posted',
    type: 'number',
    required: false,
  },
  originalWoNo: {
    bcKey: 'Original_WO_No',
    type: 'string',
    required: false,
  },
  notificationCount: {
    bcKey: 'Notification_Count',
    type: 'number',
    required: false,
  },
  masterEquipId: {
    bcKey: 'Master_Equip_ID',
    type: 'string',
    required: false,
  },
  noOpenSteps: {
    bcKey: 'No_Open_Steps',
    type: 'number',
    required: false,
  },
  noClosedSteps: {
    bcKey: 'No_Closed_Steps',
    type: 'number',
    required: false,
  },
  standardTimeOpenSteps: {
    bcKey: 'Standard_Time_Open_Steps',
    type: 'number',
    required: false,
  },
  standardTimeClosedSteps: {
    bcKey: 'Standard_Time_Closed_Steps',
    type: 'number',
    required: false,
  },
  estimatedTimeOpenSteps: {
    bcKey: 'Estimated_Time_Open_Steps',
    type: 'number',
    required: false,
  },
  estimatedTimeClosedSteps: {
    bcKey: 'Estimated_Time_Closed_Steps',
    type: 'number',
    required: false,
  },
  actualTimeOpenSteps: {
    bcKey: 'Actual_Time_Open_Steps',
    type: 'number',
    required: false,
  },
  actualTimeClosedSteps: {
    bcKey: 'Actual_Time_Closed_Steps',
    type: 'number',
    required: false,
  },
  approvalPending: {
    bcKey: 'Approval_Pending',
    type: 'boolean',
    required: false,
  },
  crewCode: {
    bcKey: 'Crew_Code',
    type: 'string',
    required: false,
  },
  plannedTechnicianCount: {
    bcKey: 'Planned_Technician_Count',
    type: 'number',
    required: false,
  },
  recordedTechnicianCount: {
    bcKey: 'Recorded_Technician_Count',
    type: 'number',
    required: false,
  },
  recordedPersonnelGroupCount: {
    bcKey: 'Recorded_Personnel_Group_Count',
    type: 'number',
    required: false,
  },
  postedTechnicianCount: {
    bcKey: 'Posted_Technician_Count',
    type: 'number',
    required: false,
  },
  postedPersonnelGroupCount: {
    bcKey: 'Posted_Personnel_Group_Count',
    type: 'number',
    required: false,
  },
  jobDescription: {
    bcKey: 'Job_Description',
    type: 'string',
    required: false,
  },
  jobTaskDescription: {
    bcKey: 'Job_Task_Description',
    type: 'string',
    required: false,
  },
  technicianFilter: {
    bcKey: 'Technician_Filter',
    type: 'string',
    required: false,
  },
  technicianExistsOnLine: {
    bcKey: 'Technician_Exists_On_Line',
    type: 'boolean',
    required: false,
  },
  technicianExistsOnTimesheet: {
    bcKey: 'Technician_Exists_On_Timesheet',
    type: 'boolean',
    required: false,
  },
  equipmentReferenceNo: {
    bcKey: 'Equipment_Reference_No',
    type: 'string',
    required: false,
  },
  linkPurchasesToJob: {
    bcKey: 'Link_Purchases_to_Job',
    type: 'boolean',
    required: false,
  },
  startingDate: {
    bcKey: 'Starting_Date',
    type: 'string',
    required: false,
  },
  startingTime: {
    bcKey: 'Starting_Time',
    type: 'string',
    required: false,
  },
  endingDate: {
    bcKey: 'Ending_Date',
    type: 'string',
    required: false,
  },
  endingTime: {
    bcKey: 'Ending_Time',
    type: 'string',
    required: false,
  },
} as const satisfies ODataMap;

type WorkOrder = ODataObjectMap<typeof WorkOrderMap>;

const WorkOrderSchema = generateGraphQlSchema('WorkOrder', WorkOrderMap);
const WorkOrderQuery = generateGraphQlQuery('WorkOrder', WorkOrderMap);
const AllWorkOrderQuery = generateGraphQlQuery('AllWorkOrder', WorkOrderMap);

const WorkOrderFields: (typeof WorkOrderMap)[keyof WorkOrder]['bcKey'][] = [
  'Actual_Time',
  'Actual_Usage',
  'Approval_Pending',
  'Area',
  'Company_ID_No',
  'Created_By',
  'Creation_Date',
  'Crew_Code',
  'Description',
  'Document_Date',
  'Document_Tracking_No',
  'Document_Type',
  'Due_By_Date',
  'Due_Date',
  'Ending_Datetime',
  'Enterprise',
  'Equip_Main',
  'Equip_Primary',
  'Equip_Secondary',
  'Equipment_Description',
  'Equipment_Group',
  'Equipment_ID',
  'Estimated_Time',
  'Expected_Usage',
  'Expiration_Date',
  'Facility',
  'Finished_Lines_Exist',
  'Job_Description',
  'Job_Line_Type',
  'Job_No',
  'Job_Task_Description',
  'Job_Task_No',
  'Last_Modified',
  'Line',
  'Link_Purchases_to_Job',
  'Location',
  'Maint_Type',
  'Managed_by_TAG_Project',
  'No_Closed_Steps',
  'No_Open_Steps',
  'No',
  'Order_Date',
  'Order_Type',
  'OSP_No',
  'Personnel_Group',
  'Planned_Technician_Count',
  'Posted_Personnel_Group_Count',
  'Posted_Technician_Count',
  'Posted_Time_Qty',
  'Posting_Error_Text',
  'Priority_Rank',
  'Priority',
  'Problem_Code',
  'Reason_Code',
  'Recorded_Personnel_Group_Count',
  'Recorded_Technician_Count',
  'Recorded_Time_Qty',
  'Region',
  'Request_No',
  'Requested_Service_Date',
  'Requester',
  'Starting_Datetime',
  'Status',
  'Supervisor_Code',
  'Technician_Code',
  'Technician_Name',
  'Unfinished_Lines_Exist',
  'Work_Code',
  'X_Coordinates',
  'Y_Coordinates',
];

export {
  type WorkOrder,
  WorkOrderSchema,
  WorkOrderQuery,
  WorkOrderFields,
  AllWorkOrderQuery,
  WorkOrderMap,
};
